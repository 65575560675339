import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import linkedin from '../../static/img/linkedin.png'
// import twitter from '../../static/img/twitter.png'
// import webriqmad from '../../static/img/webriqgoesmad.png'
import WebriqForm from './Form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './footer.css'

class Footer extends React.Component {
  // componentDidMount() {
  //   if (window) {
  //     const activescript = document.createElement('script')
  //     activescript.type = 'text/javascript'
  //     activescript.src = `https://webriqservices.activehosted.com/f/embed.php?id=1`
  //     document.body.appendChild(activescript)
  //   }
  // }

  render() {
    return (
      <footer className="footer">
        <div className="footer-bottom bg-dirty-white py-5">
          <Container>
            <Row>
              <Col md={6} lg={`${4} mb-4 mb-lg-0`}>
                <h5 className="mb-4">ABOUT US</h5>
                <p>
                  A diverse group of people coming from four continents with
                  backgrounds in sales management, web design, open source web
                  development, cloud based content management platforms and
                  digital marketing.
                </p>
                <ul className="footer-info list-unstyled ml-0">
                  <li>
                    <LazyLoadImage
                      className="mr-2"
                      effect="blur"
                      src="/img/email-icon.png"
                    />
                    <a href="mailto:info@webriq.com" title="info@webriq.com">
                      info@webriq.com
                    </a>
                  </li>
                  <li>
                    <LazyLoadImage
                      className="mr-2"
                      effect="blur"
                      src="/img/phone-icon.png"
                    />
                    <a href="tel://+15034366644" title="+15034366644">
                      +1 503 436 6644
                    </a>
                    &nbsp;/&nbsp;{' '}
                    <a href="tel://5168582325" title="5168582325">
                      +1 516 858 2325
                    </a>{' '}
                  </li>

                  <p className="mb-1">
                    <strong>US or INTL:</strong>
                  </p>
                  <li>
                    <LazyLoadImage
                      className="mr-2"
                      effect="blur"
                      src="/img/location-icon.png"
                    />
                    <span>30 N Gould ST Ste R Sheridan WY 82801</span>
                  </li>
                  <p className="mb-1">
                    <strong>SINGAPORE:</strong>
                  </p>
                  <li>
                    <LazyLoadImage
                      className="mr-2"
                      effect="blur"
                      src="/img/location-icon.png"
                    />
                    <span>160 Robinsons Road #14-04 Singapore </span>
                  </li>
                </ul>
              </Col>
              <Col md={6} lg={`${4} mb-4 mb-lg-0`}>
                <h5 className="mb-4">NEWSLETTER SIGN-UP</h5>
                <p className="mb-0">Join our mailing list to receive updates</p>
                <WebriqForm
                  webriqform="true"
                  name="Newsletter"
                  className="newsletter-form"
                  data-form-id="5df32ff0254c2c2241d670f5"
                  data-thankyou-url="https://webriqgoesmad.webriq.com/pricing"
                >
                  <Form.Group className="mb-0">
                    <Form.Control type="text" name="email" required placeholder="info@mail.com"/>
                    <Form.Label htmlFor="email">
                      Enter your email<span>*</span>
                    </Form.Label>
                  </Form.Group>
                  <small>Please verify your request*</small>
                  <Form.Group className="mt-3">
                    <div className="webriq-recaptcha" />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Button type="submit">Sign up</Button>
                  </Form.Group>
                </WebriqForm>
                {/*<div className="_form_1" />/*}

                {/*<ul className="footer-icons list-unstyled d-flex justify-content-start align-items-center mt-3">
                                  <li className="mr-3">
                                    <a
                                      href="https://twitter.com/WebriQ"
                                      title="twitter"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LazyLoadImage
                                        width={30}
                                        effect="blur"
                                        src={twitter}
                                        alt="twitter"
                                      />
                                    </a>
                                  </li>
                                  <li className="mr-3">
                                    <a
                                      href="https://www.linkedin.com/in/philippebodart/"
                                      title="philippebodart linkedin"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LazyLoadImage
                                        width={30}
                                        effect="blur"
                                        src={linkedin}
                                        alt="philippebodart linkedin"
                                      />
                                    </a>
                                  </li>
                                  <li className="mr-3">
                                    <a
                                      href="https://webriqgoesmad.webriq.com/"
                                      title="webriq goes mad"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LazyLoadImage
                                        width={30}
                                        effect="blur"
                                        src={webriqmad}
                                        alt="webriq goes mad"
                                      />
                                    </a>
                                  </li>
                                  <li className="mr-3">
                                    <a
                                      href="https://www.linkedin.com/in/alexbelding/"
                                      title="alexbelding linkedin"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LazyLoadImage
                                        width={30}
                                        effect="blur"
                                        src={linkedin}
                                        alt="alexbelding linkedin"
                                      />
                                    </a>
                                  </li>
                                </ul>*/}
              </Col>
              <Col md={12} lg={4}>
                <h5 className="mb-4">OUR PARTNERS</h5>
                <div className="partners-section mt-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://webriq.com/engagement"
                  >
                    <img
                      src="/img/partners_zoho.png"
                      alt="Zoho Authorized Partner"
                      className="img-fluid"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.socialseo.com/digital-marketing-services/local-seo"
                  >
                    <img
                      src="/img/partners_socialseo.png"
                      alt="Social SEO"
                      className="img-fluid"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.leadroll.co/"
                  >
                    <img
                      src="/img/LeadRoll-Logo.png"
                      alt="LeadRoll"
                      className="img-fluid"
                    />
                  </a>
                  <Link to="/headless-ecommerce">
                    <img
                      src="/img/BigCommerce-Logo.png"
                      alt="Bigcommerce"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright">
          <Container>
            <p className="my-4">
              All rights reserved! WebriQ Pte Ltd{' '}
              <Link to="/terms">Terms of Use</Link> |{' '}
              <Link to="/privacy">Privacy Policy</Link> |{' '}
              <Link to="/gdpr">About GDPR</Link> |{' '}
              <Link to="/cookies">Cookies</Link>
            </p>
          </Container>
        </div>
      </footer>
    )
  }
}

export default Footer
