import React from "react"
import { Link } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap";
import Sticky from 'react-sticky-el'
import { bubble as Menu } from 'react-burger-menu'
import { Motion, spring, presets} from 'react-motion'
import Slider from 'react-slick'
import logo from "../../static/img/webriq.png";
import Navmenu from './Menu'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import './header.css'
import './menu.css'



class Header extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false,
      toggle: false
    }
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen, toggle: !this.state.toggle})
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu () {
    this.setState({menuOpen: !this.state.menuOpen})
  }
  render () {
    const { location, currentpage } = this.props
    const style = {
        overflow: 'visible',
        cursor: 'pointer',
        // disable touch highlighting on devices
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
      }
    const settings = {
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      adaptiveHeight: true,
      infinite: true,
      slidesToShow: 1,
      vertical: true,
      focusOnSelect: false
    }
    return (
      <header>

        <Sticky
          className="sticky-wrapper"
          stickyClassName="is-sticky"
          stickyStyle={{ transform: 'unset', zIndex: '4' }}
          >
            <div style={{ position: 'absolute', width: '100%', top: 0, left: 0 , zIndex: 1}} className={`menu-container ${location.pathname === '/blog' || location.pathname === `/blog/` || location.pathname === `/blog/${currentpage}` || location.pathname === '/insights' || location.pathname === `/insights/` ? 'blog-menu' : ''}`}>
            <div className="header-top">
              <Container>
              <LazyLoadComponent>
                <Slider {...settings}>
                 <div className="slick-slide">
                  <a href="https://res.cloudinary.com/dnla85pdq/image/upload/v1594358323/webriq/files/The_3_Pillars_of_Modern_Digital_Marketing.pdf" className="text-white" target="_blank" rel="noreferrer noopener">E-book 3-Pillars of Digital Marketing After COVID</a>
                 </div>
                 <div className="slick-slide">
                  <a href="https://res.cloudinary.com/dnla85pdq/image/upload/v1594358349/webriq/files/headless-ecommerce.pdf" className="text-white" target="_blank" rel="noreferrer noopener">Headless Commerce breaks the monolithic mold of eCommerce</a>
                 </div>
                 <div className="slick-slide">
                  <Link to="/ecommerce" className="text-white">Special eCommerce offer</Link>
                 </div>
                </Slider>
              </LazyLoadComponent>
              </Container>
            </div>
            <div className="header-menu">
              <Container>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <Link to="/">
                        <img
                          src={logo}
                          alt="webriq"
                          className={`logoimg mb-0`}
                          width={67}
                          height={67}
                        />
                        <span className="font-weight-bold text-white ml-2 d-none d-sm-inline">WebriQ</span>
                      </Link>
                    </Col>
                    <Col xs={`${6} d-flex justify-content-end align-items-center`}>
                    <ul className="menu-login list-unstyled">
                      <li className="mr-2 mr-sm-3">
                        <a href="https://app.webriq.com/auth/login" target="_blank" rel="noopener noreferrer">
                        <img className="mb-0" src="/img/login.svg" alt="login" />
                        <span className="ml-2 d-none d-sm-inline">Login</span>
                        </a>
                      </li>
                      <li className="mr-2 mr-sm-3">
                        <a href="https://app.webriq.com/auth/register" target="_blank" rel="noopener noreferrer">
                        <img className="mb-0" src="/img/register.svg" alt="register" />
                        <span className="ml-2 d-none d-sm-inline">Register</span>
                        </a>
                      </li>
                    </ul>
                    <h3 className="bm-burger-button float-right text-white my-0" onClick={() => this.toggleMenu()}>
                       <svg
                          viewBox="0 0 96 96"
                          height="1em"
                          style={style}
                        >
                          <Motion
                            style={{
                              x: spring(this.state.toggle ? 1 : 0, presets.wobbly ),
                              y: spring(this.state.toggle ? 0: 1, presets.wobbly ),
                            }}
                          >
                            {({ x, y }) =>
                              <g
                                id="navicon"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="6"
                                strokeLinecap="butt"
                                strokeLinejoin="round"
                               >
                                <line transform={`translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`} x1="7" y1="26" x2="89" y2="26"/>
                                <line transform={`translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`} x1="7" y1="70" x2="89" y2="70"/>
                                <line transform={`translate(${x * -96})`} opacity={y} x1="7" y1="48" x2="89" y2="48"/>
                              </g>
                            }
                          </Motion>
                        </svg>
                      </h3>
                      <Menu
                        right
                        isOpen={this.state.menuOpen}
                        onStateChange={(state) => this.handleStateChange(state)}
                        id={ "bubble" }
                        customBurgerIcon={false}
                        pageWrapId={ "page-wrap" }
                        outerContainerId={ "outer-container" }
                        htmlClassName={ "bm-locked" }
                        morphShapeClassName={ "bm-bubble" }
                        bodyClassName={ "bm-open" }
                        itemListClassName={ "bm-menu-nav" }
                      >
                        <Navmenu/>
                      </Menu>

                    </Col>
                  </Row>
                </Container>
                </div>
            </div>
        </Sticky>
      </header>

    )
  }
}


export default Header
