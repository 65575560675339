import React from 'react'
import { Link } from 'gatsby'
import { Image } from 'react-bootstrap'
import linkedin from '../../static/img/linkedin.png'
import twitter from '../../static/img/twitter.png'
import facebook from '../../static/img/facebook-logo.png'
import './menu.css'

export default () => (
  <div className="navigation">
    <ul className={`m-0 menu list-unstyled`}>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <a
          href="https://webriqgoesmad.webriq.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Business Solutions
        </a>
      </li>
      <li>
        <a
          href="https://glue.webriq.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Corporate Solutions
        </a>
      </li>
      <li>
        <Link to="/seo">SEO</Link>
      </li>
      <li>
        <Link to="/engagement">Engagement</Link>
      </li>
      <li>
        <Link to="/blog">Blog</Link>
      </li>
      <li>
        <Link to="/insights">Insights</Link>
      </li>
      <li>
        <Link to="/headless-ecommerce">Headless eCommerce</Link>
      </li>
      <li>
        <Link to="/agency">Partners</Link>
      </li>
    </ul>
    <ul className={`ml-0 quicklinks list-unstyled mt-4`}>
      <li className="mb-0">
        <Link to="/terms">Terms of Use</Link>
      </li>
      <li className="mb-0">
        <Link to="/privacy">Privacy Policy</Link>
      </li>
      <li className="mb-0">
        <Link to="/gdpr">About GDPR</Link>
      </li>
      <li className="mb-0">
        <Link to="/cookies">Cookies</Link>
      </li>
    </ul>
    <ul
      className={`ml-0 leftIcons list-unstyled d-flex justify-content-start align-items-center`}
    >
    <li className="mr-3">
        <a
          href="https://www.facebook.com/webriqgoesmad"
          title="facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image width="30" src={facebook} alt="facebook" />
        </a>
      </li>
      <li className="mr-3">
        <a
          href="https://twitter.com/WebriQgoesMad"
          title="twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image width="30" src={twitter} alt="twitter" />
        </a>
      </li>
      <li className="mr-3">
        <a
          href="https://www.linkedin.com/in/philippebodart/"
          title="philippebodart linkedin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image width="30" src={linkedin} alt="philippebodart linkedin" />
        </a>
      </li>
      <li className="mr-3">
        <a
          href="https://www.linkedin.com/in/alexbelding/"
          title="alexbelding linkedin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image width="30" src={linkedin} alt="alexbelding linkedin" />
        </a>
      </li>
    </ul>
  </div>
)
